import React, { useState, useEffect } from 'react';

function Pending({nrFiles}) {

    return (
        nrFiles !== null && nrFiles > 0 && (
            <>
                <small> Extracting {nrFiles} {nrFiles == 1 ? "file" : "files"} </small>
                <span className="spinner-grow spinner-grow-sm"></span>
            </>
        )
    )

}

export default Pending