import React, { useState } from 'react';

function NavBar({ activeTab, setActiveTab }) {
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

    const toggleOffcanvas = () => {
        setIsOffcanvasOpen(!isOffcanvasOpen);
    };

    return (
        <div className="data-container">
            <div className="title-container">

                <div className="mobile-nav-container">
                    <div className="logo-container">
                        <h6>
                            <a className="logo-link" href="/data">
                                )|( &nbsp; <b> IDEM </b>
                            </a>
                        </h6>
                    </div>

                    <div>
                        {/* Offcanvas toggle button, visible only on mobile */}
                        <button
                            className="navbar-toggler d-block d-md-none"
                            type="button"
                            onClick={toggleOffcanvas}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>

                {/* Regular navigation for larger screens */}
                <nav className="nav nav-pills nav-fill d-none d-md-flex">
                    <a
                        className={`text-sm-center nav-link ${activeTab === 'data' ? 'active' : ''}`}
                        onClick={() => { setActiveTab('data'); window.location.href = "/data"; }}
                    >
                        Data
                    </a>
                    <a
                        className={`nav-link ${activeTab === 'account' ? 'active' : ''}`}
                        onClick={() => { setActiveTab('account'); window.location.href = "/settings"; }}
                    >
                        Account
                    </a>
                    
                </nav>

                {/* Offcanvas menu for mobile */}
                <div className={`offcanvas offcanvas-start ${isOffcanvasOpen ? 'show' : ''}`} tabIndex="-1">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasLabel">Menu</h5>
                        <button type="button" className="btn-close text-reset" onClick={toggleOffcanvas}></button>
                    </div>
                    <div className="offcanvas-body">
                        <nav className="nav nav-pills nav-fill">
                            <a
                                className={`text-sm-center nav-link ${activeTab === 'data' ? 'active' : ''}`}
                                onClick={() => { setActiveTab('data'); window.location.href = "/data"; }}
                            >
                                Data
                            </a>
                            <a
                                className={`nav-link ${activeTab === 'account' ? 'active' : ''}`}
                                onClick={() => { setActiveTab('account'); window.location.href = "/settings"; }}
                            >
                                Account
                            </a>
                        </nav>
                    </div>
                    <div>
                        <button
                            className="dropdown-item"
                            onClick={() => {
                                localStorage.setItem("token", null);
                                window.location.href = "/login";
                            }}
                        >
                            <i class="bi bi-box-arrow-left"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className='logout-media-query'>
                <button
                    className="dropdown-item"
                    onClick={() => {
                        localStorage.setItem("token", null);
                        window.location.href = "/login";
                    }}
                >
                    <i class="bi bi-box-arrow-left"></i>
                </button>
            </div>
        </div>
    );
}

export default NavBar;
