import React, { useState, useRef } from 'react';


function LinkModal({ shareLink }) {
    const toastRef = useRef(null);
    const [showToast, setShowToast] = useState(false);

    // Function to copy the link to clipboard
    const handleCopyToClipboard = () => {
        console.log(shareLink)
        if (shareLink) {
            navigator.clipboard.writeText(shareLink).then(() => {
                const toast = new window.bootstrap.Toast(toastRef.current);
                toast.show();

            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        }
    };

    return (
        <div>
            {/* Modal */}
            <div className="modal manual" id="linkModal" aria-hidden="true" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Share</h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>

                        <div className="modal-body-add">
                            <p><b>Click the copy icon to copy the link</b></p>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control no-hover"
                                    value={shareLink}
                                    readOnly
                                />
                                <button
                                    className="btn btn-outline-secondary no-hover"
                                    onClick={handleCopyToClipboard}
                                    type="button"
                                >
                                    <i className="bi bi-clipboard"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           {/* Toast component */}
           <div className="toast-container position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }}>
                <div ref={toastRef} className="toast align-items-center text-bg-secondary border-0" role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="d-flex">
                        <div className="toast-body">
                            Copied to clipboard!
                        </div>
                        <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LinkModal;
