import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import apiRequest from '../utils'

const Signup = () => {
    // State for holding email, username, and login error
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [loginError, setLoginError] = useState(""); // To hold error message

    const [urlSegments, setUrlSegments] = useState([]);

    useEffect(() => {
      // Get the current URL path (excluding domain)
      const path = window.location.pathname;
  
      // Split the URL path into segments based on '/'
      const segments = path.split('/').filter(Boolean); // filter(Boolean) removes empty strings from array
  
      // Update state with URL segments
      setUrlSegments(segments);
    }, []);

    // Email validation function
    const validateEmail = (email) => {
        // Regex for validating email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Validation function
    const validateForm = () => {
        if (!validateEmail(email)) {
            setLoginError("Invalid email format.");
            return false;
        }

        if (!username) {
            setLoginError("Username cannot be empty.");
            return false;
        }

        setLoginError(""); // Clear error if validation passes
        return true;
    };

    // Handle signup function
    const handleSignup = async () => {
        if (!validateForm()) {
            return; // Exit if validation fails
        }

        const token = localStorage.getItem("token");
        const signupData = JSON.stringify({ email, username });


        let request = await apiRequest(`users/`, "POST", true, token, signupData);
        if (request.status == 201) {
            setLoginError("ok")
        }
        else {
            console.log(request["data"].detail)
            setLoginError(request["data"].detail)
        }

    };

    return (
        <div className="app-container">
            <div className="login-container">
                <div className="login-items">
                    <div className='title-container-login w-100'>
                        <div>
                            <h6> )|( <b> <br/> IDEM  </b> </h6>
                        </div>
                    </div>
                    <div>
                        <input
                            type="email"
                            className="form-control login-form"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="form-control login-form"
                            placeholder="Enter username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="d-grid gap-2 w-100">
                        <button disabled={loginError == "ok" ? "disabled" : ""} className="login-btn btn btn-dark btn-block" onClick={handleSignup}>
                            {loginError == "ok" ? "CHECK YOUR INBOX" : "SIGN UP"}
                        </button>
                    </div>
                    {loginError && (
                        <div className="d-grid gap-2 w-100">
                            <small> {loginError !== "ok" && loginError} </small>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Signup;
