import React, { useState, useEffect, useRef } from 'react';
import Pending from '../components/Pending';
import LabResults from '../components/LabResults';
import apiRequest from '../utils';
import Modal from '../components/Modal';
import LinkModal from '../components/LinkModal';
import NavBar from '../components/Navigation';

function Data() {
    const [labResults, setLabResults] = useState([]);
    const [nrFilesExtracting, setNrFilesExtracting] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [LOINCCodes, setLOINCCodes] = useState([]);
    const [activeTab, setActiveTab] = useState('data'); // State to track active tab
    const [loading, setLoading] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState(''); // State to track the selected person
    const [connectedPeople, setConnectedPeople] = useState([]); // State to store list of connected people
    
    const [shareDuration, setShareDuration] = useState(null);
    const [shareLink, setShareLink] = useState(null)
    
    const shareLinkRef = useRef(null)

    // Load list of connected people
    const loadConnectedPeople = async () => {
        let request = await apiRequest("permissions/requesting", "GET", true, localStorage.getItem("token"));
        if (request.status === 200) {
            setConnectedPeople(request.data.filter(p => p.active === true));
        }
    };

    const loadLOINCCodes = async () => {
        let request = await apiRequest("tests/", "GET", true, localStorage.getItem("token"));
        if (request.status === 200) {
            setLOINCCodes(request["data"]);
        }
    };

    const getFiles = async () => {
        const person = selectedPerson === '' ? '' : selectedPerson; // Treat empty string as the logged-in user
        let request = await apiRequest(`files/${person}`, "GET", true, localStorage.getItem("token"));
        if (request.status === 401) {
            window.location.href = "/login";
        }
        else {
            let filesExtracting = request["data"].filter(file => file.extracted === false);
            if (filesExtracting.length !== null && filesExtracting.length === 0) {
                for (let i = 1; i < 100; i++) {
                    window.clearInterval(i);
                }
                loadData(true);
            }
            else {
                loadData();
            }
            setNrFilesExtracting(filesExtracting.length);
        }
    };

    useEffect(() => {
        loadConnectedPeople();
        getFiles();
        const interval = setInterval(() => {
            getFiles();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    // Trigger data load whenever selectedPerson changes
    useEffect(() => {
        loadData(true);  // This will load data when selectedPerson changes
    }, [selectedPerson]);

    const loadData = async (change = false) => {
        setLoading(true);
        const person = selectedPerson === '' ? '' : selectedPerson; // Treat empty string as the logged-in user
        if (localStorage.getItem("labdata") === null) {  // Check if there's no data in local storage
            let request = await apiRequest(`results/${person}`, "GET", true, localStorage.getItem("token"));
            if (request.status === 401) {
                window.location.href = "/login";
            } else {
                setLabResults(request["data"]);  // Set the state with the fetched data
                localStorage.setItem("labdata", JSON.stringify(request["data"])); // Store as JSON string
            }
        } else {
            if (change) {
                let request = await apiRequest(`results/${person}`, "GET", true, localStorage.getItem("token"));
                if (request.status === 401) {
                    window.location.href = "/login";
                }
                else {
                    setLabResults(request["data"]);  // Set the state with the fetched data
                    localStorage.setItem("labdata", JSON.stringify(request["data"])); // Store as JSON string
                }
            }
            // If data exists, parse it back into a JavaScript object
            setLabResults(JSON.parse(localStorage.getItem("labdata")));
        }
        setLoading(false);
    };

    const downloadData = (type) => {
        const jsonString = JSON.stringify(type === "all" ? labResults : filteredLabResults, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const link = document.createElement('a');
        const downloadName = type === "all" ? "all" : searchTerm;
        link.download = `${downloadName}.json`;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    const selectedPersonPermission = selectedPerson !== '' ? connectedPeople.filter(p => parseInt(p.target_user) === parseInt(selectedPerson))[0].relation : 2

    const extractData = async () => {
        const person = selectedPerson === '' ? '' : selectedPerson; // Treat empty string as the logged-in user
        let request = await apiRequest(`results/auto/${person}/`, "POST", true, localStorage.getItem("token"));
        if (request.status === 401) window.location.href = "/login";
        else loadData(true);
    };

    const uploadFiles = async (event) => {
        const files = Array.from(event.target.files);
        const formData = new FormData();
        const person = selectedPerson === '' ? '' : selectedPerson; // Treat empty string as the logged-in user

        files.forEach((file) => formData.append(file.name, file));
        let request = await apiRequest(`files/${person}`, "POST", true, localStorage.getItem("token"), formData);
        if (request.status === 401) window.location.href = "/login";
        else {
            extractData();
            for (let i = 1; i < 100; i++) {
                window.clearInterval(i);
            }
            getFiles();
            setInterval(() => {
                getFiles();
            }, 5000);
        }
    };

    
    const oneTimeShare = async (duration) => {

        let request = await apiRequest("shares/", "POST", true, localStorage.getItem("token"), JSON.stringify({
            "duration": duration
        }));
        
        if (request.status === 200){
            const apiUrl = process.env.REACT_APP_FRONTEND;
            const uuid = request.data["uuid"]
            const shareLink = apiUrl + "share/" + uuid
            setShareLink(shareLink)
            shareLinkRef.current.click()
        }
    }

    const filteredLabResults = labResults.filter((data_point) =>
        data_point.test_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    // Handle person selection change
    const handlePersonChange = (event) => {
        setSelectedPerson(event.target.value);
    };

    return (
        <>
            <div className="container" data-bs-theme="dark">
                <NavBar activeTab={activeTab} setActiveTab={setActiveTab} />

                <div className='title-search'>
                    <div className='title-pending'>
                        <h2> Results </h2>

                        {connectedPeople.length > 0 &&
                            <div>
                                <select
                                    className="form-select form-select-sm"
                                    onChange={handlePersonChange}
                                    value={selectedPerson}
                                    aria-label="Select person"
                                >
                                    <option value="">You</option>
                                    {connectedPeople.map(person => (
                                        <option key={person.relation} value={person.target_user}>
                                            {person.target_username}
                                        </option>
                                    ))}
                                </select>
                            </div>}
                        <div>
                            <Pending nrFiles={nrFilesExtracting} />
                        </div>
                    </div>

                    <div className="buttons-and-search">
                        <input
                            data-bs-theme="dark"
                            className='form-control form-control-sm'
                            type="text"
                            placeholder='Search test'
                            value={searchTerm}
                            onInput={(e) => setSearchTerm(e.target.value)}
                        />

                        <div className="dropdown" data-bs-theme="dark">
                            <button type="button" className="action-btn btn btn-outline-dark" data-bs-toggle="dropdown" aria-expanded="false">
                                Download
                            </button>
                            <ul className="dropdown dropdown-menu">
                                <li><button className="dropdown-item" onClick={() => downloadData("all")}>Download all</button></li>
                                <li><button className="dropdown-item" disabled={filteredLabResults.length === labResults.length ? "disabled" : ""} onClick={() => downloadData("filtered")}>Download filtered</button></li>
                            </ul>
                        </div>

                        <div className="dropdown" data-bs-theme="dark">
                            {(selectedPerson === '' || selectedPersonPermission === 2) &&
                                <button type="button" className="action-btn btn btn-outline-dark" data-bs-toggle="dropdown" aria-expanded="false">
                                    Add
                                </button>}
                            <ul className="dropdown dropdown-menu">
                                <li>
                                    <button className="dropdown-item" onClick={() => document.getElementById('fileInput').click()}>
                                        Upload
                                        <input type="file" id="fileInput" multiple onChange={uploadFiles} style={{ display: 'none' }} />
                                    </button>
                                </li>
                                <li>
                                    <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={loadLOINCCodes}> Manual </button>
                                </li>
                            </ul>
                        </div>
                        {!selectedPerson && <div className="dropdown" data-bs-theme="dark">
                            <button type="button" className="action-btn btn btn-outline-dark"
                                    onClick={() => oneTimeShare(1)}>
                                Share
                            </button>
                        </div>}
                    </div>

                </div>

                <button ref={shareLinkRef} data-bs-toggle="modal" data-bs-target="#linkModal" hidden></button>
                <LabResults selectedPerson={selectedPerson} labResults={filteredLabResults} loadData={loadData} loading={loading} />
                <Modal selectedPerson={selectedPerson} loadData={loadData} LOINCCodes={LOINCCodes} />              
                <LinkModal shareLink={shareLink}/>

            </div>
        </>
    );
}

export default Data;
