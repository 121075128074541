import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Landing = () => {

    return (
        <div className="login-container">
            <div className='title-container-login'>
                <div>
                    <h6> )|( <b> <br /> IDEM </b> </h6>
                </div>
                <p className="landing-text">in-silico Biological Twin</p>
                <div className="landing-btns">
                    <button className="login-btn landing-btn btn btn-dark" onClick={() => { window.location.href = "/login" }}>
                        LOGIN
                    </button>

                    <button className="login-btn landing-btn btn btn-dark" onClick={() => { window.location.href = "/signup" }}>
                        SIGN UP
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Landing;
