import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import apiRequest from '../utils'

const ForgotPass = () => {
    // State for holding email, username, and login error
    const [email, setEmail] = useState("");
    const [loginError, setLoginError] = useState(""); // To hold error message

    // Email validation function
    const validateEmail = (email) => {
        // Regex for validating email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Validation function
    const validateForm = () => {
        if (!validateEmail(email)) {
            setLoginError("Invalid email format.");
            return false;
        }

        setLoginError(""); // Clear error if validation passes
        return true;
    };

    // Handle signup function
    const handleSignup = async () => {
        if (!validateForm()) {
            return; // Exit if validation fails
        }

        const token = localStorage.getItem("token");
        const resetData = JSON.stringify({ email });


        let request = await apiRequest(`password-reset/`, "POST", false, null, resetData);
        if (request.status == 201) {
            setLoginError("We sent you an email to reset your password.")
        }
        else {
            setLoginError(request["data"].detail)
        }

    };

    return (
        <div className="app-container">
            <div className="login-container">
                <div className="login-items">
                    <div className='title-container-login w-100'>
                        <div>
                            <h6> )|( <b> <br/> IDEM  </b> </h6>
                        </div>
                    </div>
                    <div>
                        <input
                            type="email"
                            className="form-control login-form"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="d-grid gap-2 w-100">
                        <button className="login-btn btn btn-outline-dark btn-block" onClick={handleSignup}>
                            REQUEST PASSWORD RESET
                        </button>
                    </div>
                    {loginError && (
                        <div className="d-grid gap-2 w-100">
                            <small className=""> {loginError} </small>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ForgotPass;
