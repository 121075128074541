import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import apiRequest from '../utils'

const Activate = () => {
    // State for holding passwords, login error, and URL segments
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [loginError, setLoginError] = useState(""); // To hold error message

    const [urlSegments, setUrlSegments] = useState([]);

    useEffect(() => {
      // Get the current URL path (excluding domain)
      const path = window.location.pathname;
  
      // Split the URL path into segments based on '/'
      const segments = path.split('/').filter(Boolean); // filter(Boolean) removes empty strings from array
  
      // Update state with URL segments
      setUrlSegments(segments);
    }, []);

    // Password validation function
    const validatePasswords = () => {
        // Regex for at least 8 characters, at least 1 number, and at least 1 uppercase letter
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/

        if (!passwordRegex.test(password1)) {
            setLoginError("Password must be at least 8 characters long, contain at least one uppercase letter and one number.");
            return false;
        }

        if (password1 !== password2) {
            setLoginError("Passwords do not match.");
            return false;
        }

        setLoginError(""); // Clear error if validation passes
        return true;
    };

    // Handle login function
    const generatePasswords = async () => {
        if (!validatePasswords()) {
            return; // Exit if validation fails
        }

        const token = localStorage.getItem("token");
        const loginData = JSON.stringify({ password1, password2 });

        try {
            let request = await apiRequest(`${urlSegments[0]}/${urlSegments[1]}/${urlSegments[2]}`, "POST", true, token, loginData);
            if (request.status == 200){
                window.location.href = "/login"
            }
        } catch (error) {
            console.error(error);
            setLoginError("Error activating account.");
        }
    };

    return (
        <div className="app-container">
            <div className="login-container">
                <div className="login-items">
                    <div className='title-container-login w-100'>
                        <div>
                            <h6> )|( <b> <br/> IDEM  </b> </h6>
                        </div>
                    </div>
                    <div>
                        <input
                            type="password"
                            className="form-control login-form"
                            placeholder="Enter password"
                            value={password1}
                            onChange={(e) => setPassword1(e.target.value)}
                        />
                    </div>
                    <div>
                        <input
                            type="password"
                            className="form-control login-form"
                            placeholder="Re-enter password"
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                        />
                    </div>
                    <div className="d-grid gap-2 w-100">
                        <button className="login-btn btn btn-dark btn-block" onClick={generatePasswords}>
                            {urlSegments[0] == "reset" ? "CHANGE PASSWORD": "ACTIVATE"}
                        </button>
                    </div>
                    {loginError && (
                    <div className="d-grid gap-2 w-100">
                            <small>{loginError}</small>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Activate;
