async function apiRequest(endpoint, method = "GET", authRequired = false, token = null, body = null) {

    // Base API URL
    const apiUrl = process.env.REACT_APP_API_URL;

    const okCodes = [200, 201]

    let headers = typeof body == "string" || typeof body == {} ? { "Content-Type": "application/json" } : {}
    if (authRequired) { headers.Authorization = "Bearer " + token }

    let data = null;
    const request = await fetch(apiUrl + endpoint, { method: method, headers: headers, body: body });

    if (request.url.includes("api/file/")) {
        return request.blob()
    }
    else {
        data = await request.json()
        return { "status": request.status, "data": data }
    }
}

export default apiRequest;