import React, { useEffect, useState } from "react";
import NavBar from '../components/Navigation';
import apiRequest from "../utils";

function Settings() {

    const [subscriptionData, setSubscriptionData] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [userData, setUserData] = useState(null);

    const [loginError, setLoginError] = useState(""); // To hold error message

    const [activeTab, setActiveTab] = useState("account"); // Track the active tab
    const [localActiveTab, setLocalActiveTab] = useState("profile"); // Track the active tab

    const [searchUsername, setSearchUsername] = useState('');  // New state for search
    const [usernameList, setUsernameList] = useState([]);

    const [grantedByPermissions, setGrantedByPermissions] = useState(null)
    const [sentPermissions, setSentPermissions] = useState(null)
    const [incomingPermissions, setIncomingPermissions] = useState(null)
    const [grantedToPermissions, setGrantedToPermissions] = useState(null)


    // Load list of connected people
    const loadSentOrGrantedByPermissions = async () => {
        let request = await apiRequest("permissions/requesting", "GET", true, localStorage.getItem("token"));
        if (request.status === 200) {

            const data = request.data

            const grantedBy = data.filter(p => p.active === true)
            setGrantedByPermissions(grantedBy)

            const sentPermissions = data.filter(p => p.active === false)
            setSentPermissions(sentPermissions)
        }
    };

    const loadIncomingOrGrantedToPermissions = async () => {
        let request = await apiRequest("permissions/accepting", "GET", true, localStorage.getItem("token"));
        if (request.status === 200) {

            const data = request.data

            const grantedTo = data.filter(p => p.active === true)
            setGrantedToPermissions(grantedTo)

            const incomingPermissions = data.filter(p => p.active === false)
            setIncomingPermissions(incomingPermissions)
        }
    };


    const subscribe = async () => {
        const token = localStorage.getItem("token");
        let request = await apiRequest("subscribe/", "POST", true, token, null);
        if (request.status === 200) {
            window.open(request["data"]["url"], "_blank");
        }
    };

    const handleUsername = (search_string) => {
        setSearchUsername(search_string);
    };


    const goCustomerPortal = () => {
        window.open(subscriptionData["portal_url"], "_blank");
    };

    const filteredUsername = () => {
        if (searchUsername !== '') {
            return usernameList.filter((data_point) => data_point.username.toLowerCase().startsWith(searchUsername.toLowerCase()))
        } else {
            return [];
        }
    };


    const downloadFile = async (fileId) => {
        const token = localStorage.getItem("token");
        let request = await apiRequest(`file/${fileId}`, "GET", true, token, null);
        const url = window.URL.createObjectURL(request);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${fileId}.pdf`; // Define the file name for download
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };

    const handleSignup = async () => {
        const email = userData.email;
        const resetData = JSON.stringify({ email });
        let request = await apiRequest(`password-reset/`, "POST", false, null, resetData);
        if (request.status === 201) {
            setLoginError("We sent you an email to reset your password.");
        } else {
            setLoginError(request["data"].detail);
        }
    };

    const getUserData = async () => {
        const token = localStorage.getItem("token");
        let request = await apiRequest("user/", "GET", true, token, null);
        if (request.status === 200) {
            setUserData(request["data"]);
        }
    };

    const getSubscriptionData = async () => {
        const token = localStorage.getItem("token");
        let request = await apiRequest("subscriptions/", "GET", true, token, null);
        if (request.status === 200) {
            setSubscriptionData(request["data"]);
        }
    };

    const getUserFiles = async () => {
        const token = localStorage.getItem("token");
        let request = await apiRequest("files/", "GET", true, token, null);
        if (request.status === 200) {
            setFileData(request["data"]);
        }
    };


    const getUsernames = async () => {
        const token = localStorage.getItem("token");
        let request = await apiRequest("users/", "GET", true, token, null);
        if (request.status === 200) {
            setUsernameList(request["data"]);
        }
    };

    const requestPermission = async (targetUserId, permissionType) => {
        const token = localStorage.getItem("token");
        const requestData = JSON.stringify({
            target_user: targetUserId,
            permission_type: permissionType
        });
        let request = await apiRequest("permissions/requesting/", "POST", true, token, requestData);

        if (request.status === 201) {
            console.log("here")
            loadIncomingOrGrantedToPermissions();
            loadSentOrGrantedByPermissions();
        }
    };

    const replyPermission = async (requestId, accepted) => {
        const token = localStorage.getItem("token");
        const requestData = JSON.stringify({
            accepted: accepted
        });
        let request = await apiRequest(`permissions/accepting/${requestId}`, "POST", true, token, requestData);
        if (request.status === 200) {
            loadIncomingOrGrantedToPermissions();
            loadSentOrGrantedByPermissions();
        }
    };

    const removePermission = async (requestId) => {
        const token = localStorage.getItem("token");
        let request = await apiRequest(`permissions/${requestId}`, "DELETE", true, token);
        if (request.status === 200) {
            loadIncomingOrGrantedToPermissions();
            loadSentOrGrantedByPermissions();
        }
    }

    useEffect(() => {
        getSubscriptionData();
        getUserFiles();
        getUserData();
        getUsernames();
        loadIncomingOrGrantedToPermissions();
        loadSentOrGrantedByPermissions();
    }, []);

    const computePermissionsOnUser = (targetUser) => {

        const sent = sentPermissions.filter(p => p.target_user == targetUser)
        const granted = grantedByPermissions.filter(p => p.target_user == targetUser)

        if (sent.length > 0) {
            return sent[0].relation
        }

        else if (granted.length > 0) {
            return granted[0].relation
        }

        else {
            return 0
        }

    }

    return (
        <div className="container" data-bs-theme="dark">
            <NavBar activeTab={activeTab} setActiveTab={setActiveTab} />
            {/*<div>
                        <div className="dropdown" data-bs-theme="dark">
                            <button
                                type="button"
                                className="btn btn-outline-dark btn-account"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i className="bi bi-person-circle"></i>
                            </button>
                            <ul className="dropdown dropdown-menu">
                                <li>
                                    <button
                                        onClick={() => { window.location.href = "/settings"; }}
                                        className="dropdown-item"
                                    >
                                        <i className="bi bi-gear"></i> &nbsp; Account
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className="dropdown-item"
                                        onClick={() => {
                                            localStorage.setItem("token", null);
                                            window.location.href = "/login";
                                        }}
                                    >
                                        <i className="bi bi-box-arrow-left"></i> &nbsp; Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>*/}



            <div className="settings-container">
                <h2> Account </h2>

                <div className="nav-settings-container">

                    {/* Nav */}
                    <ul className="nav nav-pills flex-column nav-fill">
                        <li className="nav-item">
                            <button
                                className={`nav-link ${localActiveTab === "profile" ? "active" : ""}`}
                                onClick={() => setLocalActiveTab("profile")}
                            >
                                Profile
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${localActiveTab === "subscription" ? "active" : ""}`}
                                onClick={() => setLocalActiveTab("subscription")}
                            >
                                Subscription
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${localActiveTab === "files" ? "active" : ""}`}
                                onClick={() => setLocalActiveTab("files")}
                            >
                                Files
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${localActiveTab === "people" ? "active" : ""}`}
                                onClick={() => setLocalActiveTab("people")}
                            >
                                People
                            </button>
                        </li>
                    </ul>

                    {/* Settings Content based on Active Tab */}
                    <div className="settings-categories-container">
                        {localActiveTab === "profile" && (
                            <div className="settings-item">

                                <h4 className="settings-title">Profile</h4>
                                <hr></hr>
                                {userData ? (
                                    <div className="settings-title-container">
                                        <div className="subscription-info">
                                            <div>
                                                <p><b>Username</b><br /> {userData.username}</p>
                                            </div>
                                            <div>
                                                <p><b>Email</b><br /> {userData.email}</p>
                                            </div>
                                            <br/>
                                            <button disabled={loginError ? "disabled" : ''} className="btn btn-outline-dark" onClick={handleSignup}>
                                                {loginError ? "CHECK INBOX" : 'RESET PASSWORD'}
                                            </button>
                                        </div>

                                    </div>
                                ) : ""}
                            </div>
                        )}

                        {localActiveTab === "subscription" && (
                            <div className="settings-item">

                                <h4 className="settings-title">Subscription</h4>
                                <hr></hr>
                                {subscriptionData ? (
                                    <div className="settings-title-container">
                                        <div className="subscription-info">
                                            <div>
                                                <p><b>Premium</b><br /> {subscriptionData.premium ? "Yes" : "No"}</p>
                                            </div>
                                            <div>
                                                <p><b>Pages extracted</b><br /> {subscriptionData.pages_extracted}</p>
                                            </div>
                                            <div>
                                                <p><b>Pages left</b><br /> {subscriptionData.premium ? 150 - subscriptionData.pages_extracted : 50 - subscriptionData.pages_extracted}</p>
                                            </div>
                                            <div className="subscription-title-manage">
                                                <br/>
                                                {subscriptionData ? subscriptionData.premium ?
                                                    <button className="btn btn-outline-dark btn-manage" onClick={goCustomerPortal}>
                                                        Manage
                                                    </button> :
                                                    <button className="btn btn-premium" onClick={subscribe}>
                                                        Premium
                                                    </button>
                                                    : ""}
                                            </div>
                                        </div>

                                    </div>
                                ) : (
                                    <p>Loading subscription data...</p>
                                )}
                            </div>
                        )}

                        {localActiveTab === "files" && (
                            <div className="settings-item">
                                <h4 className="settings-title">Files</h4>
                                <hr></hr>
                                {fileData ? (
                                    <div className="user-files-list">
                                        {fileData.map((file) => (
                                            <div className="user-item" key={file.id}>
                                                <div>{file.filename}</div>
                                                <button onClick={() => { downloadFile(file.id) }} className="btn btn-outline-light">
                                                    <i className="bi bi-download"></i>
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                ) : <p>Loading files data...</p>}
                            </div>
                        )}

                        {localActiveTab === "people" && (
                            <div className="settings-item">
                                <h4 className="settings-title">People</h4>
                                <div className='row mt-3'>
                                    <div className="col">
                                        <div className="dropdown">
                                            <input
                                                className='form-control'
                                                type="text"
                                                placeholder='Search username'
                                                value={searchUsername}
                                                onInput={(e) => handleUsername(e.target.value)}
                                            />
                                            <ul className={`dropdown-menu full ${searchUsername.length == 0 ? "no-show" : 'show'}`}>
                                                {filteredUsername().slice(0, 10).map((test) => (
                                                    <li className="permission-item-menu">
                                                        <div>
                                                            {test.username}
                                                        </div>
                                                        <div className="permission-btn-type">
                                                            <button
                                                                className="btn btn-outline-dark"
                                                                onClick={() => requestPermission(test.pk, 1)}
                                                                disabled={(computePermissionsOnUser(test.pk) === 2 ||
                                                                    computePermissionsOnUser(test.pk) === 1)
                                                                    ? "disabled"
                                                                    : ""
                                                                } // Disable if no permission
                                                            >
                                                                Read
                                                            </button>
                                                            <button
                                                                className="btn btn-outline-dark"
                                                                onClick={() => requestPermission(test.pk, 2)}
                                                                disabled={(computePermissionsOnUser(test.pk) === 2)
                                                                    ? "disabled"
                                                                    : ""
                                                                } // Disable if no permission
                                                            >
                                                                Write
                                                            </button>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <br></br>

                                <h5 className="settings-title">Permissions</h5>
                                <hr></hr>
                                <h6 className="settings-title">Granted by</h6>
                                {grantedByPermissions.length > 0 ? grantedByPermissions.map(p => {
                                    return (
                                        <div className="permission-item" >
                                            <div>{p.target_username}</div>
                                            <small>{p.relation === 2 ? "WRITE" : "READ"}</small>
                                        </div>
                                    )
                                }
                                ) : "You haven't been granted any permission yet."
                                }
                                {<pre></pre>}
                                <h6 className="settings-title">Granted to</h6>
                                {grantedToPermissions.length > 0 ? grantedToPermissions.map(p => {
                                    return (
                                        <div className="permission-item" >
                                            <div>{p.initiating_username}</div>
                                            <div className="permission-btn-type">
                                                <small>{p.relation === 2 ? "WRITE" : "READ"}</small>
                                                <button className="btn btn-danger" onClick={() => removePermission(p.id)}>
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                                )
                                    : "No permission was granted to you."
                                }
                                <hr />
                                <h5 className="settings-title">Requests</h5>
                                <hr></hr>
                                <h6 className="settings-title">Incoming</h6>
                                {incomingPermissions.length > 0 ? incomingPermissions.map(request => {
                                    return (
                                        <div className="permission-item" >
                                            <div>{request.initiating_username} ({request.relation === 2 ? "Write" : "Read"}) </div>
                                            <div className="permission-btn-type">
                                                <button className="btn btn-success" onClick={() => replyPermission(request.id, 1)}>
                                                    Accept
                                                </button>
                                                <button className="btn btn-danger" onClick={() => replyPermission(request.id, 0)}>
                                                    Reject
                                                </button>
                                            </div>
                                        </div>

                                    )
                                }): "No incoming request."}
                                {<pre></pre>}
                                <h6 className="settings-title">Sent</h6>
                                {sentPermissions.length > 0 ? sentPermissions.map(request => {
                                    return (
                                        <div className="permission-item" >
                                            <div>{request.target_username}</div>
                                            <div>{request.relation === 2 ? "Write" : "Read"}</div>
                                        </div>

                                    )
                                }): "No pending requests."}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;
