import React, { useState, useEffect, useRef } from 'react';
import apiRequest from '../utils';
import LabResults from '../components/LabResults';

function ShareViewer() {

    const [labResults, setLabResults] = useState([]);


    const loadDataFromUUID = async (uuid) => {
        let request = await apiRequest(`share/${uuid}`, "GET", true, localStorage.getItem("token"));
        if (request.status === 200) {
            setLabResults(request["data"]);  // Set the state with the fetched data
        }
    }

    useEffect(() => {
        // Get the current URL path (excluding domain)
        const path = window.location.pathname;

        // Split the URL path into segments based on '/'
        const segments = path.split('/').filter(Boolean); // filter(Boolean) removes empty strings from array


        loadDataFromUUID(segments[1])
    }, [])

    return (
        <div className="container" data-bs-theme="dark">
            <div className="data-container">
                <div className="title-container">

                    <div className="mobile-nav-container">
                        <div className="logo-container">
                            <h6>
                                <a className="logo-link" href="/data">
                                    )|( &nbsp; <b> IDEM </b>
                                </a>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <LabResults isShare={true} labResults={labResults}></LabResults>
        </div>

    )
}

export default ShareViewer;