import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import apiRequest from '../utils';

const Login = () => {
    // State for holding username, password, and redirection
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const [shake, setShake] = useState(false); // For the shake animation
    const [buttonClass, setButtonClass] = useState("login-btn btn btn-dark btn-block");

    const toData = () => {
        window.location.href = "/data";
    };

    const checkToken = async () => {
        const token = localStorage.getItem("token");
        if (token) {
            let request = await apiRequest("check_token/", "GET", true, token);
            if (request["status"] === 200) toData();
        }
    };

    // Check token on load using useEffect
    useEffect(() => {
        checkToken();
    }, []);

    // Handle login function
    const handleLogin = async (e) => {
        e.preventDefault(); // Prevent form submission default behavior
        setLoginError(""); // Reset login error

        // Validate that username and password are not empty
        if (username === "" || password === "") {
            setLoginError("Username or password cannot be empty!");
            setShake(true);
            setButtonClass("login-btn btn btn-danger btn-block"); // Make button red
            setTimeout(() => setShake(false), 500); // Remove shake after animation duration
            return;
        }

        const loginData = JSON.stringify({ username, password });
        let request = await apiRequest("token/", "POST", true, null, loginData);

        if (request["status"] === 200) {
            localStorage.setItem("token", request["data"].access);
            toData();
        } else {
            setLoginError("Credentials not found!");
            setShake(true); // Trigger shake effect
            setButtonClass("login-btn btn btn-danger btn-block"); // Make button red
            setTimeout(() => setShake(false), 500); // Remove shake after animation duration
        }
    };

    // Reset error and button style when the user starts typing again
    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
        setLoginError(""); // Reset error message
        setButtonClass("login-btn btn btn-dark btn-block"); // Reset button color to default
    };

    return (
        <div className={`app-container ${shake ? "shake" : ""}`}>
            <div className="login-container">
                <form onSubmit={handleLogin} className="login-items">
                    <div className='title-container-login w-100'>
                        <div>
                            <h6> )|( <b> <br/> IDEM </b> </h6>
                        </div>
                    </div>
                    <div>
                        <input
                            type="text"
                            className="form-control login-form"
                            placeholder="Username"
                            value={username}
                            onChange={handleInputChange(setUsername)}
                        />
                    </div>
                    <div>
                        <input
                            type="password"
                            placeholder="Password"
                            className="form-control login-form"
                            value={password}
                            onChange={handleInputChange(setPassword)}
                        />
                    </div>
                    <div className="d-grid gap-2 w-100">
                        <button className={buttonClass} type="submit">
                            LOGIN
                        </button>
                    </div>
                    <div className="login-error">
                        <small>{loginError}</small>
                    </div>
                    <div className="forgot-password">
                        <a href="/forgotPass">Forgot password?</a>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default Login;
