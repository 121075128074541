import React, { useState, useEffect, useRef } from 'react';
import apiRequest from '../utils';

function Modal({ selectedPerson, LOINCCodes, loadData }) {

    const [dbTest, setDbTest] = useState('');  // New state for search
    const [added, setAdded] = useState("")
    const [testList, setTestList] = useState([])
    const [manualLOINC, setManualLOINC] = useState('')
    const [manualValue, setManualValue] = useState('')
    const [manualCompany, setManualCompany] = useState('')
    const [insertingManual, setInsertingManual] = useState(false)
    const [error, setError] = useState('')
    const [showToast, setShowToast] = useState(false); // State for toast visibility
    const toastRef = useRef(null); // Reference to the toast element


    useEffect(() => {
        setTestList(LOINCCodes);
    }, [LOINCCodes]);

    const handleManual = (search_string) => {
        setDbTest(search_string);
    };

    const filteredTest = () => {
        if (dbTest !== '') {
            return testList.filter((data_point) => data_point.display_name.toLowerCase().startsWith(dbTest.toLowerCase()) || data_point.test_name.toLowerCase().startsWith(dbTest.toLowerCase()) || data_point.test_loinc.toLowerCase().startsWith(dbTest.toLowerCase()) );
        } else {
            return [];
        }
    };

    const validateInputs = () => {
        if (!manualLOINC) {
            setError('Please select a LOINC code.');
            return false;
        }
        if (!manualCompany) {
            setError('Company/Note cannot be empty.');
            return false;
        }
        if (!manualValue || isNaN(manualValue)) {
            setError('Please enter a valid numeric value.');
            return false;
        }
        setError('');  // Clear any previous error messages
        return true;
    };

    const addResult = async () => {
        if (!validateInputs()) {
            return;
        }

        setInsertingManual(true);

        const person = selectedPerson === '' ? '' : selectedPerson ; // Treat empty string as the logged-in user

        const currentDate = new Date();

        const formattedCurrentDate = new Intl.DateTimeFormat('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
        }).format(currentDate).replace(',', '');

        let request = await apiRequest(`results/manual/${person}`, "POST", true, localStorage.getItem("token"), JSON.stringify({
            "test_loinc": manualLOINC,
            "company": manualCompany,
            "result_value": manualValue,
            "date": formattedCurrentDate
        }));

        if (request.status == 201) {
            setManualLOINC('');
            setManualCompany('');
            setManualValue('');
            setDbTest('');
            setInsertingManual(false);

            // Show toast on success
            setShowToast(true);
            const toast = new window.bootstrap.Toast(toastRef.current);
            toast.show();

            loadData(true);
        } else {
            setError('Failed to insert result.');
            setInsertingManual(false);
        }
    };

    return (
        <div>
            <div className="modal manual" id="exampleModal" aria-hidden="true" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Manual Entry</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body-add">
                        <div className='row mt-3'>
                                <div className="col">
                                    <div className="dropdown">
                                        <input
                                            className='form-control'
                                            type="text"
                                            placeholder='Search LOINC codes'
                                            value={dbTest}
                                            onInput={(e) => handleManual(e.target.value)}
                                        />
                                        <ul className={`dropdown-menu ${dbTest.length == 0 || manualLOINC !== "" ? "no-show" : 'show'}`}>
                                            {filteredTest().slice(0, 10).map((test) => (
                                                <li>
                                                    <button
                                                        onClick={(e) => {
                                                            handleManual(e.target.innerText);
                                                            setManualLOINC(test.test_loinc);
                                                        }}
                                                        className="dropdown-item">
                                                        [{test.test_loinc}] - {test.display_name} {test.uom ? '(' + test.uom + ')' : ''} - {test.test_name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className="col">
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder='Company/Note'
                                        value={manualCompany}
                                        onInput={(e) => setManualCompany(e.target.value)}
                                    />
                                </div>
                                <div className="col">
                                    <input
                                        type="text"
                                        placeholder='Value'
                                        className='form-control'
                                        value={manualValue}
                                        onInput={(e) => setManualValue(e.target.value)}
                                    />
                                </div>
                            </div>

                            {error && <div className="alert alert-danger mt-3" role="alert">{error}</div>}
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal">Close</button>
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => addResult()}
                                disabled={insertingManual}
                            >
                                {insertingManual ? "Inserting.." : "Add"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Toast component */}
            <div className="toast-container position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }}>
                <div ref={toastRef} className="toast align-items-center text-bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="d-flex">
                        <div className="toast-body">
                            Result successfully added!
                        </div>
                        <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
