
import React, { useEffect } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Dot } from 'recharts';


function ResultsPlot({ labResults, resultName, uom, title }) {

    for (let i = 0; i < labResults.length; i++) {
        labResults[i][resultName] = parseFloat(labResults[i]["result_value"][0])
    }

    // Custom Tooltip Component
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{
                    backgroundColor: '#0a0a0a', // Midnight blue background
                    color: '#FFFFFF', // White text
                    borderRadius: '5px',
                    padding: '10px',
                    border: '1px solid #FFFFFF', // Optional border
                }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{label}</p> {/* Date */}
                    <p style={{ margin: 0 }}>{`Value: ${payload[0].value}`}</p> {/* Result Value */}
                </div>
            );
            
        }

        return null; // Return null if not active
    };

    return (
        <div style={{ textAlign: 'center' }} className='lab-plot-container'>
            
        {/* Centered title using CSS */}
        <p style={{ marginBottom: '20px' }}>{title}</p>
        <ResponsiveContainer width="100%" height={400}>
            <LineChart
                data={labResults}
                margin={{
                    top: 20,
                    right: 65,
                    bottom: 40,
                    left: 35
                }}
            >
                <CartesianGrid strokeDasharray="2 3" strokeWidth={0.12} />
                <XAxis dataKey="date"
                    padding={{ left: 50, right: 50 }} // Adds 30px padding on the left and right

                    tick={{
                        fontSize: 14, 
                        angle: 0, // Rotates the ticks by 40 degrees
                        textAnchor: "middle", // Adjusts the anchor point for better alignment
                        dy: 10
                    }} />
                <YAxis
                    padding={{ top: 20, bottom: 20 }} // Adds 30px padding on the left and right
                    tick = {{
                        fontSize: 14
                    }}
                    label={{
                        fontSize: 14,
                        value: uom,
                        angle: -90, // Rotates the label to align vertically
                        dx: -35
                    }}
                    domain={['dataMin' - 0.1, 'dataMax' + 0.1]} // Adjusts the max Y-axis value dynamically
                />
                <Tooltip content={<CustomTooltip />} />
                <Line
                    type="monotone"
                    dataKey={resultName}
                    stroke="#6ABB77" // Midnight blue color
                    strokeWidth={1} // Makes the line thicker
                    dot={<Dot cx={0} cy={0} r={3} stroke={"#6ABB77"} fill={"#6ABB77"} strokeWidth={1}></Dot>} // Increases the radius of the data points to 6
                />
            </LineChart>
        </ResponsiveContainer>
        </div>)

}

export default ResultsPlot;