import React, { useState, useRef, useEffect } from 'react';
import ResultsTable from './ResultsTable';
import ResultsPlot from './ResultsPlot';

function LabResults({ isShare, selectedPerson, labResults, loadData, loading }) {
    const [selectedResult, setSelectedResult] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedTestName, setSelectedTestName] = useState("a")

    // Group lab results by test_name
    let processedLabResults = Object.entries(Object.groupBy(labResults, ({ test_name }) => test_name));

    // Handle card click to show modal
    const handleCardClick = (name, result) => {
        setSelectedTestName(name)
        setSelectedResult(result.sort((a, b) => a.date > b.date ? 1 : -1));
        setShowModal(true);
    };

    // Close modal function
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedResult(null);
    };

    useEffect(() => {
        if (processedLabResults.length > 0) {
            const selectedToUpdate = processedLabResults.filter((data_point) => data_point[0] == selectedTestName)
            if (selectedToUpdate.length > 0) {
                setSelectedResult(selectedToUpdate[0][1].sort((a, b) => a.date > b.date ? 1 : -1))
            }

        }
    }, [labResults])

    return (
        <div className="card-grid">

            {labResults.length > 0 ? (
                processedLabResults.map((data_point) => {
                    const testName = data_point[0]; // The test_name used as key
                    const resultsArray = data_point[1]; // The array of results

                    return (
                        <div className="card" onClick={() => handleCardClick(testName, resultsArray)}>
                            <div className="card-body">
                                <h6 className="card-title"><b>{testName}</b></h6>
                                <hr></hr>
                                <p className="card-text">
                                    <span className='result'>{resultsArray[0]?.result_value} <small>{resultsArray[0]?.uom.replace("\n", "")}</small></span>
                                </p>
                            </div>
                        </div>
                    );
                })
            ) : (
                <p>{loading ? "Loading" : "No data."}</p>
            )}

            {/* Modal to show details of the selected result */}
            {showModal && (
                <div className="modal fade show" style={{ display: 'block', backgroundColor: "#000000dd" }} id="data-modal">
                    <div className="modal-dialog modal-lg" role="document"> {/* Set modal size to large */}
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{selectedResult[0]?.test_name || 'No Display Name'}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
                            </div>
                            <div className="modal-body">
                                {/* Pass the complete selectedResult to ResultsPlot */}
                                <ResultsPlot
                                    title={<pre>{selectedResult[0]?.display_name + "\n" + selectedResult[0]?.test_loinc}</pre>}
                                    labResults={selectedResult} // Ensure this contains data for plotting
                                    resultName={selectedResult[0]?.test_name || 'No Test Name'} // Pass any additional relevant info
                                    uom={selectedResult[0]?.uom || 'No UOM'} // Pass the unit of measure if needed
                                />
                                <ResultsTable isShare={isShare} selectedPerson={selectedPerson} labResults={selectedResult} loadData={loadData} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LabResults;
